import React, { useEffect, useState } from "react"
import _ from "underscore"
import { Button } from "antd"
import ActivityLogsView from "./ActivityLogsView"
import { errorAlert } from "../../utilities"
import commonStrings from "../../commonStrings"
// import InputCellTypeNumber from "../CommonComponents/Forms/InputCellTypeNumber"
import Gradelevel from "./Gradelevel"

function ProgramMatrix({
  matrix1: ProgramMatrix,
  currentSchool,
  programAssociatedSchools,
  dataYml,
  socialData,
  behaviorSupports,
  extracurricularActivity,
  adultsel,
  currentUserRole,
}) {
  // const parsedDataYml = JSON.parse(dataYml);
  const parsedDataYml = dataYml ? JSON.parse(dataYml) : null
  const parsedSocialData = socialData ? JSON.parse(socialData) : null
  const parsedbehaviorSupports = behaviorSupports
    ? JSON.parse(behaviorSupports)
    : null
  const parsedextracurricularActivity = extracurricularActivity
    ? JSON.parse(extracurricularActivity)
    : null
  const parsedadultsel = adultsel ? JSON.parse(adultsel) : null

  // const parsedSocialData = JSON.parse(socialData);
  // const parsedbehaviorSupports = JSON.parse(behaviorSupports);

  const [matrix1, setMatrix1] = useState(ProgramMatrix)

  const [matrix, setMatrix] = useState([])

  const handleErrors = (errors) => {
    errorAlert({ html: errors.join("<br/><br/>") })
  }

  const backToDashboard = () => {
    if (currentUserRole.includes("school")) {
      window.location.href = `/?school_id=${currentSchool.id}`
    } else if (currentUserRole.includes("program")) {
      window.location.href = "/"
    }
  }

  const [selectedGrades, setSelectedGrades] = useState(matrix1.selected_grades)
  useEffect(() => {
    if (!_.isEmpty(matrix1)) {
      setSelectedGrades(matrix1.selected_grades)
    }
  }, [matrix1])
  const handleGradeLevelsChange = (grades) => {
    setSelectedGrades(grades)
    const updatedMatrix = { ...matrix1, selected_grades: grades }
    setMatrix1(updatedMatrix)
  }

  const updateGradeRecords = async () => {
    if (!selectedGrades) {
      return
    }
    const gradeData = selectedGrades.map((grade) => ({
      grade: grade.grade,
      student_population: grade.student_population,
    }))

    try {
      showSaving()
      const response = await APIClient.put(
        "/program_matrix/update_selected_grades",
        { grades: gradeData },
        () => {
          hideSaving()
        },
      )
      if (response.message) {
        console.log("Success Message:", response.message)
      }
    } catch (error) {
      console.error("Error updating grades:", error)
    }
  }
  useEffect(() => {
    updateGradeRecords()
  }, [selectedGrades])

  const viewReport = () => {
    if (!_.isEmpty(matrix1.completed_items)) {
      APIClient.put(
        "/program_matrix/submit",
        {},
        () => {
          window.location.href = "/program_matrices/reports?name=SEL_Scan"
        },
        (errors) => {
          handleErrors(errors)
        },
      )
    } else {
      errorAlert({ text: "Please add at least one completed activity" })
    }
  }

  const viewAllMatrices = () => {
    APIClient.get(
      "/program_matrix/program_matrix_items/activity_status",
      (response) => {
        if (!_.isNull(response)) {
          setMatrix(response.program_matrix_items)
        } else {
          setMatrix([])
        }
      },
    )
  }
  function findObjectById(object, array) {
    const resultObject = array.find((item) => item.id === object.id)
    if (resultObject === undefined) {
      window.location.href = "/"
    }
  }

  useEffect(() => {
    viewAllMatrices()
  }, [])

  useEffect(() => {
    findObjectById(currentSchool, programAssociatedSchools)
  }, [currentSchool, programAssociatedSchools])

  return (
    <div className="rubric program-matrix mb-20">
      <div className="header d-flex justify-content-between">
        <Button
          className="btn-activity"
          block
          onClick={() => backToDashboard()}
        >
          {commonStrings.backToDashboard}
        </Button>

        <Button className="btn-activity" block onClick={() => viewReport()}>
          {commonStrings.viewReports}
        </Button>
      </div>
      <div>
        <Gradelevel
          selectedGradeLevels={
            matrix1.selected_grades
              ? matrix1.selected_grades.map((g) => g.grade)
              : []
          }
          selectedpopulation={
            matrix1.selected_grades
              ? matrix1.selected_grades.map((g) => g.student_population)
              : []
          }
          handleGradeLevelsChange={handleGradeLevelsChange}
          currentSchool={currentSchool}
          setMatrix1={setMatrix1}
          matrix1={matrix1}
        />
      </div>

      {matrix.map((value, index) => (
        <ActivityLogsView
          key={index}
          title={value.category}
          activities={value.activities}
          totalActivities={value.total_activities}
          completedActivities={value.completed_activities}
          matrix1={matrix1}
          workflowState={matrix1.workflow_state}
          dataYml={parsedDataYml}
          socialData={parsedSocialData}
          behaviorSupports={parsedbehaviorSupports}
          extracurricularActivity={parsedextracurricularActivity}
          adultsel={parsedadultsel}
        />
      ))}
    </div>
  )
}

export default ProgramMatrix
